import FormEmailSubscribe from 'components/Common/FormEmailSubscribe'
import Logo from 'components/Common/Logo'
import { setVariables } from 'lib/gtm'
import PropTypes from 'prop-types'
import SvgDog from 'public/assets/svg/dog.svg'
import SvgSuccess from 'public/assets/svg/success.svg'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styled, { css, ThemeProvider } from 'styled-components'
import { color, remCalc, fontFamily, respondTo, noGutter } from 'styles/mixins'

import { NEWSLETTER_ID } from '../../config/app.config'

const Container = styled.div`
  margin: auto;
`

const Wrapper = styled.div`
  background-color: ${color.sunshineyellow};
  text-align: center;
  color: ${color.darkGray};
  font-family: ${fontFamily.roboto};
  padding: ${remCalc(36, 46, 29, 46)};
  ${(props) =>
    props.theme.submitSuccess &&
    css`
      && {
        padding: ${remCalc(144, 46, 165, 46)};
      }
    `}
  ${respondTo.mdAndUp`
    ${(props) =>
      !props.theme.keepSmall
        ? css`
            ${noGutter}
            background-color: ${color.white};
            display: flex;
            justify-content: center;
            align-items: start;
            padding: ${remCalc(45, 0, 0, 0)};
            text-align: left;
            border-radius: ${remCalc(24)};
            ${(props) =>
              props.theme.submitSuccess &&
              css`
                align-items: center;
                && {
                  padding: ${remCalc(45, 0, 0, 0)};
                }
              `}
          `
        : css`
            padding: ${remCalc(36, 10, 29, 10)};
          `}
  `}
`

const Title = styled.div`
  font-family: ${fontFamily.gothic};
  font-size: ${remCalc(24)};
  font-weight: bold;
  line-height: ${remCalc(29)};
  color: ${color.darkGray};
`

const Description = styled.div`
  font-size: ${remCalc(14)};
  line-height: ${remCalc(16)};
  padding-top: ${remCalc(9)};
  ${respondTo.mdAndUp`
    ${(props) =>
      !props.theme.keepSmall &&
      css`
        padding-top: ${remCalc(8)};
      `}
  `}
`

const Disclaimer = styled.div`
  color: ${color.solidgray};
  font-size: ${remCalc(13)};
  line-height: ${remCalc(18)};
  padding-top: ${remCalc(16)};
  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  ${respondTo.mdAndUp`
    ${(props) =>
      !props.theme.keepSmall &&
      css`
        line-height: ${remCalc(15)};
        padding-top: ${remCalc(12)};
      `}
  `}
`

const Visual = styled.div`
  width: 100%;
  padding-top: ${remCalc(20)};
  svg {
    width: ${remCalc(99)};
  }
  ${respondTo.mdAndUp`
    ${(props) =>
      !props.theme.keepSmall
        ? css`
            ${(props) =>
              props.pos === 'middle' &&
              css`
                display: none;
              `}
            width: auto;
            height: ${remCalc(202)};
            padding-top: 0;
            svg {
              width: ${remCalc(115)};
              height: 100%;
            }
          `
        : css`
            ${(props) =>
              props.pos === 'right' &&
              css`
                display: none;
              `}
            transform: scaleX(-1);
          `}
  `}

  ${respondTo.smAndDown`
    ${(props) =>
      props.pos === 'right' &&
      css`
        display: none;
      `}
    transform: scaleX(-1);
  `}
`

const LogoWrap = styled.div`
  display: none;
  width: ${remCalc(120)};
  ${respondTo.mdAndUp`
    ${(props) =>
      !props.theme.keepSmall &&
      css`
        display: initial;
      `}
  `}
`

const Content = styled.div`
  ${respondTo.mdAndUp`
    ${(props) =>
      !props.theme.keepSmall &&
      css`
        padding-left: ${remCalc(24)};
        margin: ${remCalc(0, 32)};
        border-left: ${color.black} ${remCalc(1)} solid;
      `}
  `}
`

const Dog = ({ pos }) => {
  return (
    <Visual pos={pos}>
      <SvgDog />
    </Visual>
  )
}

const StyledSuccessWrapper = styled.div`
  ${respondTo.mdAndUp`
    ${(props) =>
      !props.theme.keepSmall &&
      css`
        display: flex;
        justify-content: center;
        padding-left: ${remCalc(45)};
        margin-top: ${remCalc(-45)};
      `}
  `}
`

const StyledSvgSuccess = styled(SvgSuccess)`
  padding-bottom: ${remCalc(22)};
  ${respondTo.mdAndUp`
    ${(props) =>
      !props.theme.keepSmall &&
      css`
        padding-bottom: 0;
      `}
  `}
`

const StyledSuccessContent = styled.div`
  color: ${color.darkGray};
  font-family: ${fontFamily.gothic};
  font-size: ${remCalc(24)};
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;
  line-height: ${remCalc(29)};
  text-align: center;
  ${respondTo.mdAndUp`
    ${(props) =>
      !props.theme.keepSmall &&
      css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: ${remCalc(0, 48, 0, 30)};
        text-align: left;
      `}
  `}
`

const SuccessMsg = styled.div`
  padding-bottom: ${remCalc(24)};
`

const Divider = styled.div`
  box-sizing: border-box;
  height: ${remCalc(1)};
  width: ${remCalc(30)};
  border: ${remCalc(1)} solid ${color.black};
  margin: auto;
  ${respondTo.mdAndUp`
    ${(props) =>
      !props.theme.keepSmall &&
      css`
        margin: inherit;
        border: ${remCalc(2)} solid ${color.black};
      `}
  `}
`

const StyledFormEmailSubscribe = styled(FormEmailSubscribe)`
  margin-top: ${remCalc(18)};
  ${respondTo.mdAndUp`
    
    ${(props) =>
      !props.theme.keepSmall &&
      css`
        margin-top: ${remCalc(0)};
      `}
  `}
`

const Newsletter = ({
  keepSmall,
  bgColor = false,
  className,
  newsletterGaData,
  newsletterGa4CustomizedParameters,
}) => {
  const [error, setError] = useState('')
  const [submitSuccess, setSubmitSuccess] = useState(false)

  const [isImpressionEventTriggered, setIsImpressionEventTriggered] = useState(false)
  const { inView, ref } = useInView()

  const sharedGa4CustomizedParameters = {
    newsletter_name: 'yp_weekly',
    trigger_point: newsletterGa4CustomizedParameters.ypTriggerPoint,
  }

  const impressionGa4CustomizedParameters = {
    ...sharedGa4CustomizedParameters,
  }

  const clickOrSystemGa4CustomizedParameters = {
    ...sharedGa4CustomizedParameters,
    method: 'email',
  }

  useEffect(() => {
    if (inView && !isImpressionEventTriggered) {
      setVariables({
        event: 'GA4_tracking',
        category: 'nl',
        subcategory: 'sign_up',
        action: 'imp',
        customized_parameters: impressionGa4CustomizedParameters,
      })

      setIsImpressionEventTriggered(true)
    }
  }, [impressionGa4CustomizedParameters, inView, isImpressionEventTriggered])

  const handleError = (errorMessage) => {
    setError(errorMessage)
  }

  const handleSubmit = () => {
    setSubmitSuccess(true)
    setVariables({
      event: 'GA4_tracking',
      category: 'nl',
      subcategory: 'sign_up',
      action: 'sys',
      customized_parameters: clickOrSystemGa4CustomizedParameters,
    })
    setVariables({
      event: 'yp-newsletter-subscribe',
      'newsletter-id': NEWSLETTER_ID,
      'newsletter-name': 'YP Weekly',
    })
  }

  return (
    <ThemeProvider theme={{ keepSmall, bgColor, error: !!error, submitSuccess }}>
      <Container className={className} ref={ref}>
        <Wrapper>
          {!submitSuccess ? (
            <>
              <LogoWrap>
                <Logo />
              </LogoWrap>
              <Content>
                <Title>Sign up for YP Weekly</Title>
                <Description>Get updates sent directly to your inbox</Description>
                <Dog pos="middle" />
                <StyledFormEmailSubscribe
                  error={error}
                  handleError={handleError}
                  handleSubmit={handleSubmit}
                  keepSmall={keepSmall}
                  newsletterClickGa4CustomizedParameters={clickOrSystemGa4CustomizedParameters}
                  newsletterGaData={newsletterGaData}
                  newsletterId={NEWSLETTER_ID}
                />
                <Disclaimer>
                  By registering, you agree to our{' '}
                  <a href="https://www.scmp.com/terms-conditions">T&C</a> and{' '}
                  <a href="https://www.scmp.com/privacy-policy">Privacy Policy</a>
                </Disclaimer>
              </Content>
            </>
          ) : (
            <StyledSuccessWrapper>
              <StyledSvgSuccess />
              <StyledSuccessContent>
                <SuccessMsg>Thank you for your subscription!</SuccessMsg>
                <Divider />
              </StyledSuccessContent>
            </StyledSuccessWrapper>
          )}
          <Dog pos="right" />
        </Wrapper>
      </Container>
    </ThemeProvider>
  )
}

Newsletter.propTypes = {
  bgColor: PropTypes.bool,
  keepSmall: PropTypes.bool,
  newsletterGaData: PropTypes.object,
  newsletterGa4CustomizedParameters: PropTypes.object,
}

Newsletter.defaultProps = {
  bgColor: false,
  keepSmall: false,
  newsletterGaData: {},
  newsletterGa4CustomizedParameters: {},
}

Newsletter.getInitialProps = ({ req }) => {
  return { req }
}

export default Newsletter
