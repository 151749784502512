import Link from 'components/Link'
import get from 'lodash/get'
import map from 'lodash/map'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { remCalc } from 'styles/mixins'

const StyledListItem = styled.span`
  display: inline-block;
  ${(props) =>
    props.isSection &&
    props.theme.hideSection &&
    css`
      display: none;
    `}
  ${(props) =>
    props.isSpark &&
    css`
      color: #fff;
      background-color: #c3ace9;
      padding: 0 ${remCalc(5)};
    `}
  &:after {
    content: '${(props) => ` ${props.separator} `}';
    display: ${(props) => (props.isLast ? 'none' : 'inline-block')};
    margin: ${remCalc(...[0, 5, 0, 3])};
  }
`

const StyledLink = styled.a`
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const Breadcrumbs = ({ className, items = [], separator = '/' }) => {
  const listItems = map(items, (item, index) => {
    const { entityUuid, href, isSection = false, isTopic = false, isSpark = false } = item
    const pathname =
      href === '/yp' ? '/_render/home' : isTopic ? '/_render/topicpage' : '/_render/sectionpage'
    return (
      <StyledListItem
        separator={separator}
        key={index}
        isLast={index === get(items, 'length') - 1}
        isSection={isSection}
        isSpark={isSpark}
      >
        <Link href={{ pathname, query: { entityUuid } }} as={href}>
          <StyledLink>{item.text}</StyledLink>
        </Link>
      </StyledListItem>
    )
  })

  return <div className={className}>{listItems}</div>
}

Breadcrumbs.propTypes = {
  items: PropTypes.array.isRequired,
  separator: PropTypes.string,
}

export default Breadcrumbs
